import { Route, Routes } from "react-router-dom";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import bannerImage from "../src/assets/images/banner1800x600.jpg";
import { DeleteAccount } from "./Components/DeleteAccount";
import { Home } from "./Components/Home";


import { topNav } from './Components/Nav';

function App() {
  return (
    <div className="container">
      {topNav}
      <img src={bannerImage} style={{maxWidth:"100%"}}/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/deleteaccount" element={<DeleteAccount />} />

      </Routes>
    </div>
  );
}

export default App;
