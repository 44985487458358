export function DeleteAccount(props) {
  return (
    <div className="pageBody">
    <h5>
      <em>Modern Coffee</em> has an in-app process for deleting accounts, but if
      you would like to remove your account from outside the app, please email{" "}
      <a href="mailto:info@robotlions.com">Robot Lions</a> from the email tied
      to your Modern Coffee account.
    </h5>
    </div>
  );
}
