
export const topNav = (
  <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
      <a className="navbar-brand brandText" href="/home">modern coffee</a>
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
      <li className="nav-item">
          <a className="nav-link" aria-current="page" href="/">Home</a>
        </li>
        
        <li className="nav-item">
          <a className="nav-link" href="/deleteaccount">Delete Account</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="https://play.google.com/store/apps/developer?id=Robot+Lions">Google Play Store</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" aria-current="page" href="https://robotlions.com">robotlions.com</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
);